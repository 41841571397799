import React, { useState, useEffect, useContext, createContext } from "react";
import api, {
  fetchTenantConfig,
  recordEvent as apiRecordEvent
} from "../services/api";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import qs from "querystring";

import eventiveConfig from "../eventive.config.json";

const query =
  typeof window !== "undefined" &&
  qs.parse(window.location.search.substring(1));

export const COOKIE_KEY = eventiveConfig?.single_organization
  ?.use_aarp_registration_flow
  ? "eventive-token-aarp"
  : "eventive-token";
const cachedToken = Cookies.get(COOKIE_KEY) || (query && query.__token) || null;

const SHOULD_PERSIST =
  typeof window !== "undefined" && "localStorage" in window;
const LS_KEY = "eventive-watchPersonState";
let cachedUser;
try {
  cachedUser =
    (!!cachedToken &&
      SHOULD_PERSIST &&
      window.localStorage[LS_KEY] &&
      JSON.parse(window.localStorage[LS_KEY])) ||
    null;
} catch (e) {
  // Catch if unable to access localstorage (e.g. due to DOM Exception 18)
  cachedUser = null;
}
const LS_ADMIN_KEY = "eventive-watchAdminPreview";
let cachedAdminPreviewSettings;
try {
  cachedAdminPreviewSettings =
    (SHOULD_PERSIST &&
      window.localStorage[LS_ADMIN_KEY] &&
      JSON.parse(window.localStorage[LS_ADMIN_KEY])) ||
    null;
} catch (e) {
  // Catch if unable to access localstorage (e.g. due to DOM Exception 18)
  cachedAdminPreviewSettings = null;
}

const authContext = createContext();

export const singleOrganization = eventiveConfig.single_organization || null;

export function AuthProvider({
  initialTenant,
  triggerLoginExpiredModal,
  children
}) {
  let initialApiLoaded = false;
  if (initialTenant) {
    api.defaults.auth = { username: initialTenant.api_key };
    initialApiLoaded = true;
  }
  const auth = useProvideAuth({
    initialTenant,
    initialApiLoaded,
    triggerLoginExpiredModal
  });
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth({
  initialTenant,
  initialApiLoaded = false,
  triggerLoginExpiredModal
}) {
  const router = useRouter();

  const [apiLoaded, setApiLoaded] = useState(initialApiLoaded);
  const [tenant, setTenant] = useState(initialTenant);
  useEffect(() => {
    if (router.query.festivalId) {
      if (!tenant || tenant.tenant !== router.query.festivalId) {
        setApiLoaded(false);
        setTenant(null);
        fetchTenantConfig(router.query.festivalId)
          .then(c => {
            api.defaults.auth = { username: c.api_key };
            setTenant(c);
            setApiLoaded(true);
          })
          .catch(e => console.log(e));
      } else {
        api.defaults.auth = { username: tenant.api_key };
        setApiLoaded(true);
      }
    } else {
      setApiLoaded(false);
      setTenant(null);

      if (eventiveConfig?.single_organization?.api_key) {
        api.defaults.auth = {
          username: eventiveConfig.single_organization.api_key
        };
      } else {
        delete api.defaults.auth;
      }
      setApiLoaded(true);
    }
  }, [router.query.festivalId]);

  const [token, setToken] = useState(cachedToken);
  const [user, setUser] = useState(cachedUser);
  const [adminPreviewSettings, setAdminPreviewSettings] = useState(null);

  const [colorPalette, setColorPalette] = useState(
    router.query.forceColorPalette ||
      (router.query.festivalId && tenant?.virtual_festival_color_palette) ||
      singleOrganization?.color_palette ||
      "black"
  );
  useEffect(() => {
    const { festivalId, forceColorPalette } = router.query;

    // Custom color palette
    setColorPalette(
      forceColorPalette ||
        (festivalId && tenant?.virtual_festival_color_palette) ||
        singleOrganization?.color_palette ||
        "black"
    );

    // Custom Google Analytics tracking
    if (festivalId && tenant?.virtual_festival_google_analytics_id) {
      if (window.__ev_fbpt !== tenant.virtual_festival_google_analytics_id) {
        if (window.__ev_gat) {
          window.ga(`${window.__ev_gat}.remove`);
        }

        window.ga(
          "create",
          tenant.virtual_festival_google_analytics_id,
          "auto",
          festivalId
        );
        window.__ev_gat = festivalId;
        window.ga(`${window.__ev_gat}.send`, "pageview");
      }
    } else {
      if (window.__ev_gat) {
        window.ga(`${window.__ev_gat}.remove`);
      }
      window.__ev_gat = null;
    }

    // Custom Facebook Pixel tracking
    if (festivalId && tenant?.virtual_festival_facebook_pixel_id) {
      if (window.__ev_fbpt !== tenant.virtual_festival_facebook_pixel_id) {
        window.__ev_fbpt = tenant.virtual_festival_facebook_pixel_id;
        window.fbq("init", window.__ev_fbpt);
        window.fbq("set", "autoConfig", false, window.__ev_fbpt);
        window.fbq.disablePushState = true; // manually fire
        window.fbq("trackSingle", window.__ev_fbpt, "PageView");
      }
    } else {
      window.__ev_fbpt = null;
    }
  }, [router.query.festivalId, tenant]);

  // Refresh self on initial load
  useEffect(() => {
    if (cachedToken) {
      api.get(`people/self?token=${cachedToken}`).then(handleAuthResponse);
    }
  }, []);

  useEffect(() => {
    let domainParts = window ? window.location.hostname.split(".") : "";
    let baseDomain = (domainParts.length >= 3
      ? domainParts.slice(1)
      : domainParts
    ).join(".");

    if (singleOrganization?.sso_silo || domainParts.indexOf("ngrok") !== -1) {
      baseDomain = window.location.hostname;
    }

    if (window.location.port) {
      // Debug mode - don't hardcode domain
      baseDomain = undefined;
    }

    if (token) {
      Cookies.set(COOKIE_KEY, token, {
        secure:
          singleOrganization?.sundance_login && // only sundance for now
          window.location.protocol === "https:", // only set it if the current protocol is https, that way it won't break for dev
        domain: baseDomain,
        expires: 30
      });
    } else {
      Cookies.remove(COOKIE_KEY, { domain: baseDomain });
    }

    if (window?.localStorage) {
      window.localStorage[LS_KEY] = JSON.stringify(user);
    }
  }, [token, user]);

  useEffect(() => {
    if (router.query.__admin_preview__) {
      router.replace(router.pathname, window.location.pathname, {
        shallow: true
      });
      if (router.query.__admin_preview__ === "exit") {
        cachedAdminPreviewSettings = null;
        setAdminPreviewSettings(null);
      } else {
        setAdminPreviewSettings(
          JSON.parse(atob(router.query.__admin_preview__))
        );
      }
    } else if (cachedAdminPreviewSettings && !adminPreviewSettings) {
      setAdminPreviewSettings(cachedAdminPreviewSettings);
      return;
    }
  }, [router.query.__admin_preview__]);

  useEffect(() => {
    if (
      adminPreviewSettings &&
      new Date(adminPreviewSettings.expiry) < Date.now()
    ) {
      setAdminPreviewSettings(null);
    }

    if (window?.localStorage) {
      window.localStorage[LS_ADMIN_KEY] = JSON.stringify(adminPreviewSettings);
    }
  }, [adminPreviewSettings]);

  const handleAuthResponse = resp => {
    const { token, ...user } = resp.data;
    if (token) {
      setToken(token);
    }
    setUser(user);
    return resp.data;
  };

  const lookup = email => api.post("people/lookup", { email });

  const login = ({ email, password, targetResource = {} }) =>
    api
      .post("people/login", { email, password, ...targetResource })
      .then(handleAuthResponse);

  const facebookLogin = ({ token, targetResource = {} }) =>
    api
      .post("people/auth_facebook", {
        token,
        register: true,
        ...targetResource
      })
      .then(handleAuthResponse);

  const idaLogin = ({ token, targetResource = {} }) =>
    api
      .post("people/auth_ida", {
        token,
        event_bucket: tenant?.event_bucket,
        ...targetResource
      })
      .then(handleAuthResponse);

  const eventivalLogin = ({ token, targetResource = {} }) =>
    api
      .post("people/auth_eventival", {
        token,
        event_bucket: tenant?.event_bucket,
        ...targetResource
      })
      .then(handleAuthResponse);

  const aarpLogin = ({ token, ticketBucket, targetResource = {} }) =>
    api
      .post("people/auth_aarp", {
        token,
        ticket_bucket: ticketBucket,
        event_bucket: tenant?.event_bucket,
        ...targetResource
      })
      .then(handleAuthResponse);

  const sundanceLogin = ({ token, eventBucket, targetResource = {} }) =>
    api
      .post("people/auth_sundance", {
        token,
        event_bucket: eventBucket,
        ...targetResource
      })
      .then(handleAuthResponse);

  const register = credentials =>
    api.post("people", { ...credentials }).then(handleAuthResponse);

  const update = details =>
    api.post("people/self", { token, ...details }).then(handleAuthResponse);

  const forgotPassword = credentials =>
    api.post("people/forgot_password", {
      ...credentials,
      from_watch: true,
      reset_url: `${window.location.protocol}//${
        window.location.host
      }/reset?next=${encodeURIComponent(window.location.href)}&token=`
    });

  const resetPassword = credentials =>
    api.post("people/reset_password", { ...credentials });

  const logout = () => {
    setToken(null);
    setUser(null);
  };

  const recordEvent = (event, payload = {}) => {
    if (adminPreviewSettings?.god) {
      // fall through
      return;
    }
    return apiRecordEvent(event, {
      ...payload,
      person_id: adminPreviewSettings
        ? `admin_preview`
        : user
        ? user.id
        : payload.person_id || undefined,
      person_email: adminPreviewSettings
        ? adminPreviewSettings.email
        : user
        ? user.details.email
        : payload.person_email || undefined,
      person_name: adminPreviewSettings
        ? `ADMIN PREVIEW - ${adminPreviewSettings.name}`
        : user
        ? user.details.name
        : payload.person_name || undefined,
      event_bucket_id: tenant
        ? tenant.event_bucket
        : payload.event_bucket_id || undefined,
      festival_id: tenant ? tenant.tenant : payload.festival_id || undefined,
      user_agent: navigator.userAgent
    });
  };

  const [hasTriggeredLoginExpired, setHasTriggeredLoginExpired] = useState(
    false
  );
  const onLoginExpired = () => {
    if (!hasTriggeredLoginExpired) {
      setHasTriggeredLoginExpired(true);
      logout();
      if (router.pathname.indexOf("bigscreen") !== -1) {
        router.replace(`/bigscreen/auth`);
      } else {
        triggerLoginExpiredModal();
      }
    }
  };
  useEffect(() => {
    api.interceptors.response.use(
      r => r,
      error => {
        let requestUrl = error.originalError?.config?.url;
        if (
          requestUrl &&
          // ignore all requests to LoginModal endpoints
          !/(people$|login|forgot_password|reset_password)/.test(requestUrl) &&
          error?.code === "InvalidCredentials"
        ) {
          onLoginExpired();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return {
    singleOrganization,
    festivalId: tenant ? tenant.tenant : null,
    colorPalette,
    api,
    apiLoaded,
    tenant,
    user,
    token,
    lookup,
    login,
    facebookLogin,
    idaLogin,
    eventivalLogin,
    aarpLogin,
    sundanceLogin,
    register,
    forgotPassword,
    resetPassword,
    update,
    logout,
    recordEvent,

    onLoginExpired,

    // used by bigscreen auth
    handleAuthResponse,

    adminPreviewSettings: adminPreviewSettings,
    exitAdminPreview: () => setAdminPreviewSettings(null)
  };
}
